<template>
  <div class="container">
    <br />
    <div class="card">
      <div class="ml-4 mr-4 mt-2">
        <b-tabs content-class="mt-3">
          <b-tab title="Gráficos">
            <Chart />
          </b-tab>
          <b-tab title="Listagem">
            <Table v-if="!$isMobile()" :Edit="Edit" />
            <MobileCards v-if="$isMobile()" :Edit="Edit" />
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal
      id="edit"
      :title="'Editar ativo: ' + codeEdit"
      @ok="Save"
      @cancel="ClearFields"
      @close="ClearFields"
      @hide="ClearFields"
      ok-title="Atualizar"
      ok-variant="cyan"
      cancel-title="Cancelar"
      cancel-variant="red"
    >
      <div>
        <div class="form-group">
          <label class="form-label">Setor</label>
          <input
            type="text"
            ref="sector"
            class="form-control"
            v-on:keyup.enter="keyupEnter('segment')"
            name="example-text-input"
            v-model="sector"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Segmento</label>
          <input
            type="text"
            ref="segment"
            class="form-control"
            v-on:keyup.enter="keyupEnterModal()"
            name="example-text-input"
            v-model="segment"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import Swal from "sweetalert2";
import Chart from "@/components/sector/Chart";
import Table from "@/components/sector/Table";
import MobileCards from "@/components/sector/MobileCards";
export default {
  components: { Chart, Table, MobileCards },
  data() {
    return {
      codeEdit: "",
      sector: "",
      segment: "",
    };
  },
  mounted() {
    this.$store.state.portfolio.DataBase.newPortfolio = false;
    firebase.analytics().logEvent("View page Sector");
  },
  computed: {
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
  },
  methods: {
    Edit(row) {
      this.codeEdit = row.code;
      this.sector = row.sector.sector;
      this.segment = row.sector.segment;
      this.$bvModal.show("edit");
      setTimeout(() => {
        this.keyupEnter("sector");
      }, 300);
    },
    ClearFields() {
      this.sector = "";
      this.segment = "";
    },
    keyupEnterModal() {
      this.Save();
      this.$bvModal.hide("edit");
    },
    keyupEnter(where) {
      this.$refs[where].focus();
      this.$refs[where].select();
    },
    Save() {
      let stock = this.$store.state.portfolio.DataBase.portfolio.stocks.filter(
        (response) => response.code == this.codeEdit
      )[0];

      stock.sector = {
        sector: this.sector,
        segment: this.segment,
      };

      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });

      this.ToastSuccess("success", "Salvo com sucesso!");
    },
    ToastSuccess(icon, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: icon,
        title: title,
      });
    },
  },
};
</script>

<style></style>
