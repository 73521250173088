<template>
    <div v-if="DataBase != null" class="container mb-4">    
        <br>

        <div class="accordion" role="tablist" 
            v-for="item in DataBase.portfolio.class" 
            :key="item.code" 
        >
            <b-card no-body class="mb-0" v-if="(DataBase.portfolio.stocks.filter(itens => itens.class == item.code).length > 0)">
                <b-card-header header-tag="header" @click="Collapse(item.code)" class="p-1" role="tab" style="cursor: pointer;">
                    <div class="acc_head p-2 text-muted">
                        <div :class="'card-value  stamp  br-100 bg-' + $options.filters.COLOR(item.code)">
                            <font-awesome-icon :icon="item.code == 'criptomoedas' ? ['fab' , $options.filters.ICON(item.code)] : $options.filters.ICON(item.code) " />
                        </div>
                        <span style="font-size:20px;">
                            {{item.code | NAME}}
                        </span>
                    </div>
                </b-card-header>
                <b-collapse :id="item.code" accordion="my-accordion" role="tabpanel" style="background-color: #f0f6ff !important">
                    
                        <b-row class="m-2" >
                            <b-col md="12" class="mt-3">
                                <div class="card">
                                    <!-- <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(item.code) + ' br-bl-7 br-tl-7'"></div> -->
                                    <div class="card-body" >
                                        <div class="text-muted">{{item.code | NAME}}</div>
                                        <div class="d-flex justify-content-between align-items-center porcent">
                                            <div>Atual {{ GetCurrentPercentage(item.code) | %}}</div>
                                            <div>Meta {{ GetObjectivePercentage(item.code) | %}}</div>
                                        </div>
                                        <div class="progress">
                                            <div :class="'progress-bar ' + GetColor(GetPercent(GetCurrentPercentage(item.code), GetObjectivePercentage(item.code)))" :style="'width:' + (isFinite(GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)) ? GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)  : 100) + '%'">
                                            </div>
                                        </div>
                                        <div class="text-center pt-0">
                                            {{ isFinite(GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)) ? GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)  : '100' | %}}
                                        </div>
                                    </div>
                                </div>    
                            </b-col>
                            <b-col md="6" lg="4" xl="3"  v-for="stocks in DataBase.portfolio.stocks.filter(itens => itens.class == item.code)" :key="stocks.code"  >
                                <div class="card">
                                    <!-- <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(stocks.class) + ' br-bl-7 br-tl-7'"></div> -->
                                    <!-- <div :class="'card-status bg-' + $options.filters.COLOR(className) + ' br-tr-7 br-tl-7'"></div> -->
                                    <div class="card-body" >
                                        <div class="text-muted">{{stocks.code}}</div>
                                        <div class="d-flex justify-content-between align-items-center porcent">
                                            <small>Atual {{CurrentPercentage(stocks.code) | %}}</small>
                                            <small>Meta {{stocks.percentageObjective | %}}</small>
                                        </div>
                                        <div class="text-center">
                                            <div class="progress">
                                                <div :class="'progress-bar ' + GetColor(GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective)) " :style="'width:' + GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) + '%'">
                                                    <!-- {{GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) | %}} -->
                                                </div>
                                            </div>
                                            {{GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) | %}}
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- <div class="d-flex justify-content-between align-items-center porcent   mt-2">
                            <div>Atual {{ GetCurrentPercentage(item.code) | %}}</div>
                            <div>Meta {{ GetObjectivePercentage(item.code) | %}}</div>
                        </div>
                        <div class="progress">
                            <div :class="'progress-bar ' + GetColor(GetPercent(GetCurrentPercentage(item.code), GetObjectivePercentage(item.code)))" :style="'width:' + (isFinite(GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)) ? GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)  : 100) + '%'">
                            </div>
                        </div>
                        <div class="text-center">
                            {{ isFinite(GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)) ? GetCurrentPercentage(item.code) * 100 / GetObjectivePercentage(item.code)  : '100' | %}}
                        </div>
                        <hr>
                        <table class="table card-table table-vcenter text-nowrap table-hover">
                            <thead class="text-center">
                                <th>Código</th>
                                <th>Atual</th>
                                <th>Meta</th>
                                <th>Progresso da Meta</th>
                            </thead>
                            <tbody class="text-center">
                                <tr v-for="stocks in DataBase.portfolio.stocks.filter(itens => itens.class == item.code)" :key="stocks.code" >
                                    <td>{{stocks.code}}</td>
                                    <td>{{CurrentPercentage(stocks.code) | %}}</td>
                                    <td>{{stocks.percentageObjective | %}}</td>
                                    <td>{{stocks.percentageObjective}} -- {{CurrentPercentage(stocks.code)}}</td>
                                    <td>
                                        <div class="progress">
                                            <div :class="'progress-bar ' + GetColor(GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective)) " :style="'width:' + GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) + '%'">
                                                {{GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) | %}}
                                            </div>
                                        </div>
                                                {{GetPercent(CurrentPercentage(stocks.code) , stocks.percentageObjective) | %}}
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                    
                </b-collapse>
            </b-card>

            

        </div>
    </div>
    
</template>

<script>
import firebase from "firebase/app"
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters('portfolio', ['ValueByClass','GetObjectivePercentage', 'GetCurrentPercentage','CurrentPercentage']),
        DataBase(){
            console.log(this.$store.state.portfolio.DataBase);
            return this.$store.state.portfolio.DataBase
        },
    },
    mounted(){
		firebase.analytics().logEvent('View page Allocation');
        // this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        //     console.log('collapseId:', collapseId)
        //     console.log('isJustShown:', isJustShown)
        // })
        // this.$root.$emit('bv::toggle::collapse', 'acoes')
        // this.DataBase.portfolio.stocks.sort((a,b) => {
        //     console.log(parseFloat(this.GetPercent(this.CurrentPercentage(a.code) , a.percentageObjective)))
        //     parseFloat(this.GetPercent(this.CurrentPercentage(a.code) , a.percentageObjective)) - parseFloat(this.GetPercent(this.CurrentPercentage(b.code) , b.percentageObjective))
        //     // a.percentageDiff - b.percentageDiff
        // })
    },
    methods:{
        Collapse(code){
            this.$root.$emit('bv::toggle::collapse', code)
            this.$store.state.portfolio.DataBase.portfolio.stocks.sort((a,b) => {
                return parseFloat(this.GetPercent(this.CurrentPercentage(b.code) , b.percentageObjective)) - parseFloat(this.GetPercent(this.CurrentPercentage(a.code) , a.percentageObjective))
                // a.percentageDiff - b.percentageDiff
            })
        },
        GetPercent(now, objective){
            let Porcent = parseFloat(now * 100 / objective)
            if(parseFloat(Porcent) > 99999999999) Porcent = 0
            return Porcent

        },
        GetColor(Porcent){

            if (Porcent == 100) {
                return "bg-blue"
            } else if( Porcent < 100) {
                return "bg-green"
            } else if( Porcent > 100) {
                return "bg-red"
            } else {
                return "bg-green"
            }   

        }

    }
    
}
</script>

<style>

</style>