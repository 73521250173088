<template>
    <div class="container">    
        <br>
        <div v-if="DataBase != null" class="col-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title col-8">Notificações</h3>
                    <div class="col-4 text-right p-1" style="font-size:medium;">
                        <button type="button" class="btn btn-info btn-sm" @click="view = !view">
                            <font-awesome-icon style="cursor: pointer;" :icon=" !view ? 'envelope-open-text' : 'envelope'" /> {{$isMobile() ? '' : (view ? 'Ocultar não lidas' : 'Mostrar todas')}} 
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="fluid-container">
                        <div 
                            v-for="(itens, index) in DataBase.notification" :key="index"  
                            class="row ticket-card pb-2 border-bottom pb-3 mb-3"
                            v-show="!itens.delete && (view || !itens.view) "
                        >
                            <div class="ticket-details col-md-10">
                                <div class="d-flex">
                                    <p class="text-dark font-weight-bold mr-2 mb-0 text-nowrap">
                                        {{itens.title}}
                                    </p>
                                    <!-- <p class="font-weight-semibold mb-0 ellipsis">{{itens.title}}</p> -->
                                </div>
                                <p class="text-gray mb-2" v-html="itens.text">
                                  
                                </p>
                                <div class="row text-gray d-md-flex d-none">
                                    <div class="col-6 d-flex">
                                        <p class="mb-0 mr-2 text-muted">Recebida em :</p>
                                        <p class="mr-2 mb-0 text-muted font-weight-light">{{itens.date}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="ticket-actions col-md-2 text-right">
                                <span v-b-tooltip.hover.bottom title="Excluir" @click="Delete(itens)">
                                    <font-awesome-icon style="cursor: pointer;" icon="trash-alt" class="text-red"/>
                                </span>
                                <span class="ml-2 text-primary" v-b-tooltip.hover.bottom :title="!itens.view ? 'Marcar como lida' : 'Marcar como não lida'" @click="View(itens)">
                                    <font-awesome-icon style="cursor: pointer;" :icon=" !itens.view ? 'envelope-open-text' : 'envelope'" />
                                </span>
                            </div>
                        </div>
                        <div v-if="!HasNotification()" class="row ticket-card pl-3">
                            Nenhuma notificação encontrada!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
    data() {
		return {
			view: false,
		}
    },
    computed: {
        DataBase:{
            get: function() {return this.$store.state.portfolio.DataBase },
            set: function(newValue) {this.$store.state.portfolio.DataBase = newValue} 
        },
    },
    mounted(){
        if(this.DataBase == null || this.DataBase.notification === undefined) return 0
        this.DataBase.notification.sort((a,b) => { return b.id - a.id;})
    },
    methods:{
        HasNotification(){
            if(this.DataBase.notification === undefined) return 0
            return this.DataBase.notification.filter(item => (item.view == false && item.delete == false )).length > 0 || this.view
        },
        Delete(item){
            item.delete = true
            this.Save()
        },
        View(item){
            item.view = !item.view
            this.Save()
        },
        Save(){
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
        },
    }
  }
</script>

<style>
.bold {
    font-weight: bold;
}
</style>