<template>
    <div v-if="DataBase != null">
        <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap table-hover">
                <thead>
                    <th class="text-dark text-center"><b>#</b></th>
                    <th class="text-dark"><b>Código</b></th>
                    <th class="text-dark text-center"><b>Classe</b></th>
                    <th class="text-dark"><b>Setor</b></th>
                    <th class="text-dark"><b>Segmento</b></th>
                    <th class="text-dark text-center"><b>Editar</b></th>
                </thead>
                <tbody>
                    <tr  v-for="(itens, index) in DataBase.portfolio.stocks" :key="index">
                        <td class="text-center">{{index + 1}}</td>
                        <td class="">{{itens.code}}</td>
                        <td class="text-center" v-html="$options.filters.TAG(itens.class)"></td>
                        <td>{{itens.sector.sector }}</td>
                        <td>{{itens.sector.segment}}</td>
                        <td class="text-center">
                            <span v-b-tooltip.hover.left title="Editar"  @click="Edit(itens)">
                                <font-awesome-icon style="cursor: pointer;" icon="edit" class="text-blue"/>
                            </span> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        
    </div>
</template>

<script>
export default {
    props: {
        Edit: { type: Function, required: true },
    },
    computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
}
</script>

<style>

</style>