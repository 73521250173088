<template>
    <!-- <b-col lg="4" v-if="(GetCurrentPercentage(className) * 100 / GetObjectivePercentage(className) > 0)"> -->
    <b-col md="6" lg="4" xl="3" v-if="(GetObjectivePercentage(className) > 0 || DataBase.portfolio.stocks.filter(item => item.class == className).length > 0) ">
        <div class="card">
            <!-- <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(className) + ' br-bl-7 br-tl-7'"></div> -->
            <!-- <div :class="'card-status bg-' + $options.filters.COLOR(className) + ' br-tr-7 br-tl-7'"></div> -->
            <div class="card-body" >
                <div :class="'card-value float-right stamp  br-100 bg-' + $options.filters.COLOR(className)">
                    <font-awesome-icon :icon="className == 'criptomoedas' ? ['fab' , $options.filters.ICON(className)] : $options.filters.ICON(className) " />
                </div>
                <div :class="'text-muted text-' + $options.filters.COLOR(className)">{{className | NAME}}</div>

                <h6 :class="(showValue ? '' : 'blur') + ' mb-1' ">
                    {{ ValueByClass(className) | BRL}}
                </h6>
                <small :class="showValue ? '' : 'blur' ">
                    {{ ValueByClass(className, false) | USD}}
                </small>


                <div class="d-flex justify-content-between align-items-center porcent   mt-2">
                    <small>Atual {{ GetCurrentPercentage(className) | %}}</small>
                    <small>Meta {{ GetObjectivePercentage(className) | %}}</small>
                </div>
                <div class="progress">
                    <div :class="'progress-bar bg-' + $options.filters.COLOR(className)" :style="'width:' + (isFinite(GetCurrentPercentage(className) * 100 / GetObjectivePercentage(className)) ? GetCurrentPercentage(className) * 100 / GetObjectivePercentage(className)  : 100) + '%'">
                        {{ isFinite(GetCurrentPercentage(className) * 100 / GetObjectivePercentage(className)) ? GetCurrentPercentage(className) * 100 / GetObjectivePercentage(className)  : '100' | %}}
                    </div>
                </div>
            </div>
        </div>
    </b-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        className: { type: String, required: true },
    },
    computed:{
        ...mapGetters('portfolio', ['ValueByClass','GetObjectivePercentage', 'GetCurrentPercentage']),
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        showValue:{
            get: function() {return this.$store.state.portfolio.DataBase.settings.showValue},
            set: function(newValue) {this.$store.state.portfolio.DataBase.settings.showValue = newValue} 
        },
    },
}
</script>

<style>
    .card {
        border: 0px;
    }
</style>