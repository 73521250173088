<template>
	<div class="card" v-if="DataBase != null">
		<div class="card-header">
			<h3 class="card-title">Gráficos</h3>
			<div class="card-options" v-if="!$isMobile()">
				<button @click="type = 'doughnut2d'" :class="'btn btn' + (type != 'doughnut2d'?'-outline':'') + '-primary btn-sm'">Gráfico de pizza</button>
				<button @click="type = 'bar2d'" :class="'btn btn' + (type != 'bar2d'?'-outline':'') + '-primary btn-sm ml-2'">Gráfico de barras</button>
			</div>
		</div>

		<div class="card-body" >
			<div class="row" v-if="$isMobile()">
				<div class="col-6">
					<button @click="type = 'doughnut2d'" :class="'btn btn' + (type != 'doughnut2d'?'-outline':'') + '-primary btn-sm'">Gráfico de pizza</button>
				</div>
				<div class="col-6">
					<button @click="type = 'column2d'" :class="'btn btn' + (type != 'column2d'?'-outline':'') + '-primary btn-sm ml-2'">Gráfico de barras</button>
				</div>
				<div class="col-12"><hr></div>
			</div>
			<!-- Graficos -->
			<b-tabs content-class="mt-3">
				<b-tab @click="ClassByCurrent('Classe de ativos')" title="Classe" class="ml-2 mr-2" > </b-tab>
				<div v-for="item in DataBase.portfolio.class" :key="item.code" class="ml-2" >
					<b-tab
						@click="ByStock(item.code)"
						v-if="(
							item.objectivePercentage > 0 || 
							(DataBase.portfolio.stocks.filter(stock => stock.class == item.code).length > 0)
						)" 
						:title="$options.filters.NAME(item.code)" 
						>
					</b-tab>
				</div>
			</b-tabs>
			<div class="row">
				<div :class="'col-lg-6 ' + ($isMobile()?'':'border-right')">
					<div id="chart-container">
						<fusioncharts
							:type="type"
							:width="width"
							:height="height"
							:dataformat="dataFormat"
							:dataSource="dataSourceCurrent"
						>
						</fusioncharts>
					</div>
				</div>
				<div class="col-lg-6">
					<div id="chart-container">
						<fusioncharts
							:type="type"
							:width="width"
							:height="height"
							:dataformat="dataFormat"
							:dataSource="dataSourceGoal"
						>
						</fusioncharts>
					</div>
				</div>
			</div>
		</div>
	</div>

	
	
</template>

<script>
export default {
    data() {
		return {
			type: "doughnut2d", //
			renderAt: "chart-container",
			width: "100%",
			height: "500",
			dataFormat: "json",
			dataSourceCurrent:{},
			dataSourceGoal:{},
		}
    },
	computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
	mounted(){
		this.GetDataSource('', '')
		this.ClassByCurrent('Classe de ativos')
	},
	methods:{
		GetDataSource(caption, subcaption){
			this.dataSourceCurrent = {
				chart: {
					caption: (caption + ' - Atual'),
					subcaption: subcaption,
					numberPrefix: "R$ ",
					showPercentInTooltip: "0",
					decimals: "2",
					useDataPlotColorForLabels: "2",
					theme: "fusion"
				},
				data: []
			}
			
			this.dataSourceGoal = {
				chart: {
					caption: (caption  + ' - Meta'),
					subcaption: subcaption,
					numberPrefix: "R$ ",
					showPercentInTooltip: "0",
					decimals: "2",
					useDataPlotColorForLabels: "2",
					theme: "fusion"
				},
				data: []
			}
		},
		ClassByCurrent(caption){
			this.dataSourceCurrent.data = [];
			this.dataSourceCurrent.chart.caption = caption + ' - Atual'

			for (let index = 0; index < this.DataBase.portfolio.class.length; index++) {
				const element = this.DataBase.portfolio.class[index];
				const MyClass =  this.DataBase.portfolio.stocks.filter(item => item.class == element.code)
				if(MyClass.length == 0) continue

				let SumValue = 0

				MyClass.forEach(el => {
					SumValue = SumValue + (el.priceBRL * el.quantity)
				});

				this.dataSourceCurrent.data.push({
					"label": this.$options.filters.NAME(element.code),
					'color': this.$options.filters.COLOR_HEX(element.code),
					"value": SumValue
				})
			}


			this.dataSourceGoal.data = [];
			this.dataSourceGoal.chart.caption = caption  + ' - Meta'
			this.dataSourceGoal.chart.numberPrefix = ""
			this.dataSourceGoal.chart.numbersuffix = "%"

			for (let index = 0; index < this.DataBase.portfolio.class.length; index++) {
				const element = this.DataBase.portfolio.class[index];
				if(element.objectivePercentage == 0) continue


				this.dataSourceGoal.data.push({
					"label": this.$options.filters.NAME(element.code),
					'color': this.$options.filters.COLOR_HEX(element.code),
					"value": element.objectivePercentage
				})
			}
		},
		ByStock(ClassName){
			this.dataSourceCurrent.data = [];
			this.dataSourceCurrent.chart.caption = "Composição atual - " + this.$options.filters.NAME(ClassName)
			let stock =  this.DataBase.portfolio.stocks.filter(item => item.class == ClassName)

			stock.sort((a,b) => {  
                return (a.priceBRL * a.quantity) - (b.priceBRL * b.quantity)
            })

			for (let index = 0; index < stock.length; index++) {
				const element = stock[index];

				this.dataSourceCurrent.data.push({
					"label": element.code,
					"value": (element.priceBRL * element.quantity)
				})
			}


			this.dataSourceGoal.data = [];
			this.dataSourceGoal.chart.caption = "Metas - " + this.$options.filters.NAME(ClassName)
			this.dataSourceGoal.chart.numberPrefix = "Nota: "
			this.dataSourceGoal.chart.numbersuffix = ""

			for (let index = 0; index < stock.length; index++) {
				const element = stock[index];

				this.dataSourceGoal.data.push({
					"label": element.code,
					"value": element.note
				})
			}
		}
	}
}
</script>

<style scoped>

</style>
