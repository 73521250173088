<template>
    <div v-if=" DataBase != null">
        <div class="card mb-1" v-for="(itens, index) in DataBase.portfolio.stocks" :key="index">
            <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(itens.class) + ' br-bl-7 br-tl-7'"></div>
            
            <div class="card-body pl-4 pr-4 pt-1 pb-1 ">
                <div class="row">

                    <!-- HEADER -->
                    <div class="col-6 border-bottom p-2" style="font-size:medium;">
                        <b>{{itens.code}}</b>
                    </div>
                    <div class="col-6 text-right border-bottom p-1" style="font-size:medium;">
                        <span class="mr-2" v-html="$options.filters.TAG(itens.class)"></span>
                        <span @click="Edit(itens)">
                            <font-awesome-icon style="cursor: pointer;" icon="edit" class="text-blue"/>
                        </span>
                    </div>

                    <div class="col-12 text-center border-bottom p-1"  style="font-size:small;">
                        <b>Setor:</b> {{itens.sector.sector}}
                    </div>
                    <div class="col-12 text-center  p-1"  style="font-size:small;">
                        <b>Segmento:</b> {{itens.sector.segment}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        Edit: { type: Function, required: true },
    },
    computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
}
</script>

<style scoped>
    .card-body{
        font-size: x-small;
    }
</style>