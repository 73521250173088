<template>
    <div class="card" v-if="DataBase != null">
        <div class="card-header">
            <h3 class="card-title">Setor e Segmento</h3>
            <div class="card-options" v-if="!$isMobile()">
                <button @click="type = 'doughnut2d'" :class="'btn btn' + (type != 'doughnut2d'?'-outline':'') + '-primary btn-sm'">Gráfico de pizza</button>
                <button @click="type = 'bar2d'" :class="'btn btn' + (type != 'bar2d'?'-outline':'') + '-primary btn-sm ml-2'">Gráfico de barras</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row" v-if="$isMobile()">
                <div class="col-6">
                    <button @click="type = 'doughnut2d'" :class="'btn btn' + (type != 'doughnut2d'?'-outline':'') + '-primary btn-sm'">Gráfico de pizza</button>
                </div>
                <div class="col-6">
                    <button @click="type = 'column2d'" :class="'btn btn' + (type != 'bar2d'?'-outline':'') + '-primary btn-sm ml-2'">Gráfico de barras</button>
                </div>
                <div class="col-12"><hr></div>
            </div>
            <div class="row">
                <div class="col-6">
                    <b-dropdown id="dropdown-1" text="Selecione a categoria" class="m-md-2" variant="primary">
                        <b-dropdown-item @click="Draw('Setor', 'sector','')" class="ml-2"> 
                            <font-awesome-icon icon='caret-right' /> Todos Setores
                        </b-dropdown-item>
                        <b-dropdown-item @click="Draw('Segmento', 'segment','')" class="ml-2"> 
                            <font-awesome-icon icon='caret-right' /> Todos Segmentos
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item 
                            @click="Draw('Setor - ' + $options.filters.NAME(item.code), 'sector',item.code)" 
                            v-for="item in DataBase.portfolio.class" :key="item.code + '-sector'" 
                            v-show="(
                                item.objectivePercentage > 0 || 
                                (DataBase.portfolio.stocks.filter(stock => stock.class == item.code).length > 0)
                            )"
                            class="ml-2" 
                        >
                            <font-awesome-icon icon='caret-right' /> Setor -  {{$options.filters.NAME(item.code)}}
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item 
                            @click="Draw('Segmento - ' + $options.filters.NAME(item.code), 'segment',item.code)" 
                            v-for="item in DataBase.portfolio.class" :key="item.code + '-segment'" 
                            v-show="(
                                item.objectivePercentage > 0 || 
                                (DataBase.portfolio.stocks.filter(stock => stock.class == item.code).length > 0)
                            )"
                            class="ml-2" 
                        >
                            <font-awesome-icon icon='caret-right' /> Segmento - {{$options.filters.NAME(item.code)}}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div class="row">
                <div :class="'col-lg-6 ' + ($isMobile()?'':'border-right')">
                    <div id="chart-container">
                        <fusioncharts
                            :type="type"
                            :width="width"
                            :height="height"
                            :dataformat="dataFormat"
                            :dataSource="dataSourceCurrent"
                        >
                        </fusioncharts>
                    </div>
                </div>
                <!-- <div class="col-lg-12"><hr></div> -->
                <div class="col-lg-6">
                    <div id="chart-container">
                        <fusioncharts
                            :type="type"
                            :width="width"
                            :height="height"
                            :dataformat="dataFormat"
                            :dataSource="dataSourceGoal"
                        >
                        </fusioncharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
		return {
			type: "doughnut2d", //
			renderAt: "chart-container",
			width: "100%",
			height: "600",
			dataFormat: "json",
			dataSourceCurrent:{},
			dataSourceGoal:{},
		}
    },
    computed:{
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
	mounted(){
		this.GetDataSource('', '')
        setTimeout(() => {
            this.Draw('Setor', 'sector' , '')
        }, 500);
	},
    methods:{
		GetDataSource(caption, subcaption){
			this.dataSourceCurrent = {
				chart: {
					caption: (caption + ' - Atual'),
					subcaption: subcaption,
					numberPrefix: "R$ ",
					showPercentInTooltip: "0",
					decimals: "2",
					useDataPlotColorForLabels: "2",
					theme: "fusion"
				},
				data: []
			}
			
			this.dataSourceGoal = {
				chart: {
					caption: (caption  + ' - Meta'),
					subcaption: subcaption,
					numberPrefix: "R$ ",
					showPercentInTooltip: "0",
					decimals: "2",
					useDataPlotColorForLabels: "2",
					theme: "fusion"
				},
				data: []
			}
		},
        Draw(caption, type, className){
            if(this.DataBase == null) return

            this.DataBase.portfolio.stocks.sort((a,b) => {  
                return a.class.localeCompare(b.class);
            })

			this.dataSourceCurrent.data = [];
			this.dataSourceCurrent.chart.caption = caption + ' - Atual'


            let stocks = []
            if(className != ''){
                stocks = this.DataBase.portfolio.stocks.filter(response => response.class == className);
            }else{
                stocks = this.DataBase.portfolio.stocks
            }

			for (let index = 0; index < stocks.length; index++) {
                const element = stocks[index];
                if (element.sector === undefined) {
                    this.DataBase.settings.lastConsolidated = 1024492800000
                    setTimeout(() => {
                        this.Draw(caption, type, className)
                    }, 1000);
                    return
                }
				let ChatSectorCurrent = this.dataSourceCurrent.data.filter(response => response.label == element.sector[type]);
    
                if(ChatSectorCurrent.length > 0){
                    ChatSectorCurrent.map(e => {
                        if(e.label == element.sector[type]){
                            e.value = parseFloat(e.value) + + parseFloat(element.priceBRL * element.quantity)
                        }
                    })
                }else{
                    this.dataSourceCurrent.data.push({
                        "label":  element.sector[type],
                        "value":  (element.priceBRL * element.quantity)
                    })
                }
			}
            
			this.dataSourceGoal.data = [];
			this.dataSourceGoal.chart.caption = caption  + ' - Meta'
			this.dataSourceGoal.chart.numberPrefix = ""
			this.dataSourceGoal.chart.numbersuffix = "%"

            for (let index = 0; index < stocks.length; index++) {
				const element = stocks[index];
				let ChatSectorGoal = this.dataSourceGoal.data.filter(response => response.label == element.sector[type]);
                
                if(ChatSectorGoal.length > 0){
                    ChatSectorGoal.map(e => {
                        if(e.label == element.sector[type]){
                            e.value = parseFloat(e.value) + parseFloat(element.percentageObjective)
                        }
                    })
                }else{
                    this.dataSourceGoal.data.push({
                        "label":  element.sector[type],
                        "value":  element.percentageObjective 
                    })
                }
			}
		},
    }
}
</script>

<style>

</style>