<template>
  <div class="container">
    <div v-if="DataBase != null">
      <br />
      <b-row>
        <b-col
          :lg="
            DataBase.settings.premium.type != 1 &&
            DataBase.settings.premium.type != 2
              ? 9
              : 12
          "
        >
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col>
                  <h6>
                    Valor Investido
                    <span @click="changeShow" style="cursor: pointer">
                      <font-awesome-icon v-if="!showValue == true" icon="eye" />
                      <font-awesome-icon v-else icon="eye-slash" />
                    </span>
                  </h6>
                  <h4>
                    <span :class="showValue ? '' : 'blur'">
                      {{ PricePortifolioBRL() | BRL }}
                    </span>
                  </h4>
                  <h6 :class="showValue ? '' : 'blur'">
                    {{ PricePortifolioUSD() | USD }}
                  </h6>
                </b-col>
                <b-col v-if="!$isMobile()" class="text-right text-primary">
                  <font-awesome-icon icon="wallet" size="4x" />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col
          lg="3"
          v-if="
            DataBase.settings.premium.type != 1 &&
            DataBase.settings.premium.type != 2
          "
          class="mt-2 mb-2"
        >
          <GoogleAdds />
        </b-col>
        <DashCard
          v-for="item in AllClassPortfolio"
          :key="item.className"
          :className="item.className"
        />
        <b-col lg="12">
          <ChartjsDash />
        </b-col>
      </b-row>
      <br />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import DashCard from "@/components/widgets/DashCard";
import ChartjsDash from "@/components/widgets/ChartjsDash";
import GoogleAdds from "@/components/widgets/GoogleAdds";
import { mapGetters } from "vuex";
export default {
  components: { DashCard, ChartjsDash, GoogleAdds },
  computed: {
    ...mapGetters("portfolio", ["GetCurrentPercentage"]),
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
    AllClassPortfolio() {
      return this.$store.state.global.AllClassPortfolio;
    },
    showValue: {
      get: function () {
        return this.$store.state.portfolio.DataBase.settings.showValue;
      },
      set: function (newValue) {
        this.$store.state.portfolio.DataBase.settings.showValue = newValue;
      },
    },
  },
  mounted() {
    if (this.DataBase != null && this.showValue === undefined)
      this.changeShow();

    firebase.analytics().logEvent("View page Dashbord");

    console.log(firebase.firestore().collection("account"));

    // setTimeout(() => {
    //     // console.log(this.DataBase.portfolio.stocks.length);
    //     if(this.DataBase.portfolio.stocks.length == 0){

    //     }

    // }, 1000);
  },
  methods: {
    changeShow() {
      if (this.showValue === undefined) this.showValue = false;
      this.showValue = !this.showValue;

      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });
    },
    PricePortifolioBRL() {
      let price = 0;
      this.DataBase.portfolio.stocks.forEach((element) => {
        price += parseFloat(element.priceBRL) * parseFloat(element.quantity);
      });
      return price;
    },
    PricePortifolioUSD() {
      let price = 0;
      this.DataBase.portfolio.stocks.forEach((element) => {
        price += parseFloat(element.priceUSD) * parseFloat(element.quantity);
      });
      return price;
    },
  },
};
</script>

<style>
.dolar {
  margin-top: -10px !important;
  margin-bottom: -10px;
}
.text-size-small {
  font-size: medium;
}
.blur {
  filter: blur(6px);
}
</style>
