<template>
    <div class="container">    
        <br>
        <div class="row row-cards row-deck">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title"><font-awesome-icon icon='life-ring' /> Precisa de ajuda?</h3>
                    </div>
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">É possível trocar a moeda para dólar ?</h4>
                                    <p>Todos os campos que trabalham com valor monetário, tem do lado esquerdo um botão escrito "R$". É só clicar nele e selecionar a moeda para o sistema passar a considerar como padrão.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">Posso rebalancear somente uma classe de ativo?</h4>
                                    <p>É possível selecionar quais ativos irão entrar no cálculo, pela tela de rebalanceamento. Basta desmarcar a categoria que não quer rebalancear, que o Carteira Holder irá desconsiderá-la dos cálculos.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">Como eu sei o que cada coluna representa na tabela?</h4>
                                    <p>Ao passar o mouse em cima do título de cada coluna, nossa plataforma explica o que ela significa.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">Como lanço um ativo que acabei de comprar ou vender?</h4>
                                    <p>Basta acessar a parte de rebalanceamento, e clicar no botão que se encontra na coluna “OP”, que abrirá um pop-up onde você consegue informar a quantidade de compra ou venda. Caso esse ativo for da categoria de renda fixa, vai aparecer um novo campo para você preencher o valor da renda fixa e, nesse caso, essas informações serão atualizadas.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">Onde lanço meus ativos?</h4>
                                    <p>Acesse a carteira meta e, assim que definir a porcentagem que deseja ter em cada ativo, será possível clicar no botão “Carteira Meta” onde você conseguirá lançar todos seus ativos. Vale ressaltar que o Carteira Holder não valida se esse ativo está sendo cadastrado na categoria certa, então fique atento para lançar eles dentro de suas respectivas categorias.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">O que seria Preço Teto?</h4>
                                    <p>Preço teto é valor que você está disposto a pagar de um ativo. Ao definir um valor, se no dia do rebalanceamento o preço do ativo estiver maior do que você definiu, a Carteira Holder não indicará a compra desse ativo.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">Minha corretora americana não trabalha com casas decimais o que devo fazer?</h4>
                                    <p>Para tirar as casas decimais do rebalanceamento, clique em sua foto no canto superior direito do menu, vá em configurações e desmarque a opção de “Casas decimais em Investimentos internacionais”.</p>
                                </div>
                                <div class="card-body">
                                    <h4 class="font-weight-semibold">O que acontece quando desmarco um ativo na coluna “APT?” ou passa do preço teto?</h4>
                                    <p>O sistema pega a porcentagem objetiva desse ativo em questão, e distribui para os outros ativos que estão nessa categoria. Ex: tenho cinco ativos em FIIs, cada um com 20%. Ao desmarcar um deles, cada um dos outros quatros terão, agora, 25%.</p>
                                </div>

                                <div class="card-body ">
                                    <h4 class="font-weight-semibold">Não encontrei minha dúvida</h4>
                                    <p>
                                        Caso não tenha encontrado sua dúvida em nosso FAQ envie uma mensagem em nosso instagram <a href="https://www.instagram.com/carteiraholder/"  target="_blank" >@carteiraholder</a>  ou mande um e-mail para <a href="mailto:contato@carteiraholder.com.br">contato@carteiraholder.com.br </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
    mounted(){
		firebase.analytics().logEvent('View page Tutorial');
	},

}
</script>

<style>

</style>