<template>
    <div class="container">    
        <br>
        <div class="row row-cards row-deck">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Tutorial</h3>
                    </div>
                    <div class="card-body text-center">
                        <b-embed
                            type="iframe"
                            aspect="16by9"
                            src="https://www.youtube.com/embed/LY5QINvYQ0c"
                            allowfullscreen
                        ></b-embed>
                        <!-- <hr>
                        <h3><a href="https://carteiraholder.com.br/public/33630A41-F92A-4DE1-9EE0-6E3E0E251583.pdf" target="_blank">Clique aqui para ver o PDF</a></h3> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
    mounted(){
		firebase.analytics().logEvent('View page Tutorial');
	},

}
</script>

<style>

</style>